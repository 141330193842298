.mapboxgl-ctrl-bottom-right {
	right: 50% !important;
	bottom: 0;
	transform: translateX(50%) !important;
}
.traffic-control{
	display: none;
}
.traffic-control.icu-road-map.icu-traffic-bar-enabled{
	display: flex;
}