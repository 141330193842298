.mapboxgl-ctrl-top-right {
    top: 90px !important;
    right: 0;
}

.maplibregl-style-list{
    background: rgb(18, 21, 30);
}

.maplibregl-style-list button{
    color: #fff;
}

.maplibregl-style-list button.active{
    color: rgb(87, 199, 212);
}